import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "../components/alert";
import { BrickCard } from "./brick_card";
import { useQuery } from "react-query";
import { getAllGalleryBricks } from "../common/endpoints";
import { Tabs, TabsList, TabsTrigger } from "../components/tabs";
import { CenterScreen } from "../settings/styled_components";
export const BrickGalleryPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedBrickIds, setSelectedBrickIds] = useState(new Set());
    const [selectedBrickTypeTab, setSelectedBrickTypeTab] = useState("ACCOUNT");
    const { data: galleryPageResponse } = useQuery({
        queryKey: ["getAllGalleryBricks"],
        queryFn: getAllGalleryBricks,
        refetchOnWindowFocus: false,
    });
    const tabs = useMemo(() => {
        if (!galleryPageResponse)
            return [];
        return galleryPageResponse.brick_type_config
            .filter((brickTypeConfig) => galleryPageResponse.bricks.some((brick) => brick.research_block_type === brickTypeConfig.id))
            .map((brickTypeConfig) => {
            return {
                label: brickTypeConfig.label,
                value: brickTypeConfig.id,
            };
        });
    }, [galleryPageResponse]);
    const bricksToRender = useMemo(() => {
        if (!galleryPageResponse)
            return [];
        return galleryPageResponse.bricks.filter((brick) => brick.research_block_type === selectedBrickTypeTab);
    }, [galleryPageResponse, selectedBrickTypeTab]);
    if (!galleryPageResponse)
        return null;
    return (_jsx("div", Object.assign({ className: "w-full lg:h-screen p-8" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-8 justify-center w-full items-start" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-3.5 w-full self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between w-full self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-2xl font-medium" }, { children: "Prompts Gallery" })), _jsxs("div", Object.assign({ className: "h-10 flex items-center justify-end gap-2.5 pr-3 py-2 w-1/5 rounded-lg border border-wds-gray-3" }, { children: [_jsx("input", { type: "text", className: "w-full h-full text-base border-none focus:border-none focus:ring-0 focus:outline-none", placeholder: "Search prompt", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value) }), _jsx(MagnifyingGlassIcon, { className: "w-4 h-4 shrink-0" })] }))] })), _jsxs(Alert, Object.assign({ variant: "default" }, { children: [_jsx(AlertTitle, Object.assign({ className: "text-base font-medium text-wds-gray-6" }, { children: "How does wiser gallery work?" })), _jsx(AlertDescription, Object.assign({ className: "text-sm text-wds-gray-5" }, { children: "All the prompts under the Wiser gallery are managed by Wiser. If you want to make edits, you first need to import these to your custom prompt list" }))] }))] })), _jsx(Tabs, Object.assign({ defaultValue: tabs[0].value, onValueChange: (value) => {
                        setSelectedBrickTypeTab(value);
                        setSelectedBrickIds(new Set());
                    }, className: "w-full border-b border-wds-gray-3 pb-0.5 pt-4" }, { children: _jsx(TabsList, Object.assign({ className: "border-none gap-x-6" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, className: "outline-none py-3 px-0 text-sm font-normal justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-wds-blue-3 data-[state=active]:border-b-2 data-[state=active]:bg-white data-[state=active]:text-wds-blue-3" }, { children: tab.label }), tab.value))) })) })), _jsx("div", Object.assign({ className: "flex flex-wrap gap-4 mb-8 w-full items-start justify-start" }, { children: bricksToRender.length > 0 ? (bricksToRender.map((brick) => (_jsx(BrickCard, { brick: brick, isSelected: selectedBrickIds.has(brick.id), setIsSelected: (isSelected) => {
                            if (isSelected) {
                                setSelectedBrickIds((prev) => new Set(prev).add(brick.id));
                            }
                            else {
                                setSelectedBrickIds((prev) => {
                                    const newSet = new Set(prev);
                                    newSet.delete(brick.id);
                                    return newSet;
                                });
                            }
                        }, dataSourceConfig: galleryPageResponse.brick_data_source_config })))) : (_jsx(CenterScreen, Object.assign({ className: "h-auto w-full text-lg text-wds-gray-5 font-medium" }, { children: "No bricks available" }))) }))] })) })));
};
